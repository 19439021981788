<template>
<!-- eslint-disable-next-line vue/no-mutating-props -->
<v-dialog v-model="display" persistent max-width="390px" content-class="radius-15">
  <v-card flat class="pa-3 pt-2">
    <v-card-title class="pa-0">
      <h2 class="starling-h2">{{ title }}</h2>
    </v-card-title>
    <v-card-text class="pa-0">
        <v-layout wrap>
          <v-flex xs12>
            <p class="starling-body" v-html="description"></p>
          </v-flex>
          <slot name="afterDescription"></slot>
        </v-layout>
    </v-card-text>
    <v-card-actions class="pa-0 pt-3">
      <v-flex xs12>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 text-center>
            <action-buttons
                :primary="{ label: primaryConfirmLabel, disabled: primaryConfirmDisabled, loading: primaryConfirmLoading }"
                @primary-clicked="onConfirm"
                :secondary="{ label: secondaryCancelLabel }"
                @secondary-clicked="onCancel">
            </action-buttons>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import actionButtons from '@/views/components/navigation/action-buttons.vue';
export default {
  name: 'custom-confirmation-modal',
  components: { actionButtons },
  props: {
    display: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    primaryConfirmLabel: {
      type: String,
      required: true,
    },
    primaryConfirmDisabled: {
      type: Boolean,
      default: false,
    },
    primaryConfirmLoading: {
      type: Boolean,
      default: false,
    },
    secondaryCancelLabel: {
      type: String,
      required: true,
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel-event');
    },
    onConfirm() {
      this.$emit('confirm-event');
    },
  },
};
</script>
