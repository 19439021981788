var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "390px",
        "content-class": "radius-15"
      },
      model: {
        value: _vm.display,
        callback: function($$v) {
          _vm.display = $$v
        },
        expression: "display"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3 pt-2", attrs: { flat: "" } },
        [
          _c("v-card-title", { staticClass: "pa-0" }, [
            _c("h2", { staticClass: "starling-h2" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("p", {
                      staticClass: "starling-body",
                      domProps: { innerHTML: _vm._s(_vm.description) }
                    })
                  ]),
                  _vm._t("afterDescription")
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-0 pt-3" },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-center": "" } },
                        [
                          _c("action-buttons", {
                            attrs: {
                              primary: {
                                label: _vm.primaryConfirmLabel,
                                disabled: _vm.primaryConfirmDisabled,
                                loading: _vm.primaryConfirmLoading
                              },
                              secondary: { label: _vm.secondaryCancelLabel }
                            },
                            on: {
                              "primary-clicked": _vm.onConfirm,
                              "secondary-clicked": _vm.onCancel
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }